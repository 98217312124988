import {useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import {ActionMeta, SingleValue} from "react-select";
import {serieContentsActions} from "../../../../../_store/features/serie-content/serie-content-slice";
import {endOfToday, formatISO9075, roundToNearestMinutes, startOfDay, subDays} from "date-fns";
import {zonedTimeToUtc} from "date-fns-tz";
import {DateRange} from "react-day-picker";
import {marketingActions} from "../../../../../_store/features/marketing/marketing-slice";
import {auth} from "../../../../../firebase";
import {
	GetSubscriptionsListForMarketingParams,
	MarketingListParams,
	getUsersListForMarketing,
	getSubscriptionsListForMarketing,
} from "../../../../../data-access/marketing/marketing";
import {downloadFile} from "../../../../../../utils/downloadXLSX";
import {PaginationState} from "@tanstack/react-table";
import {SelectOptionType} from "../../../../../../utils/globalTypes";
import {seriesActions} from "../../../../../_store/features/series/series-slice";
import {SUBSCRIPTIONS_TYPES} from "../../../../../constants";

export function MarketingFiltersController() {
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	type InteractionTypes =
		| "series_views"
		| "trailer_views"
		| "content_views"
		| "membership_purchase"
		| "series_like"
		| "series_dislike"
		| "content_like"
		| "content_dislike"
		| "subscriptions"
		| null;

	type SelectMembershipsType = {label: string; value: number; isdisabled?: boolean};

	const interactionList = [
		{label: "Vistas de la Serie", value: "series_views"},
		{label: "Vistas del Trailer", value: "trailer_views"},
		{label: "Vistas del Contenido", value: "content_views"},
		{label: "Likes de la Serie", value: "series_like"},
		{label: "Likes del Contenido", value: "content_like"},
		{label: "Dislikes de la Serie", value: "series_dislike"},
		{label: "Dislikes del Contenido", value: "content_dislike"},
		{label: "Compras de Membresía", value: "membership_purchase"},
		{label: "Suscripciones", value: "subscriptions"},
	];
	const [disableDate, setDisableDate] = useState(false);
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});

	let dateFrameList = [
		{label: "Hoy", value: 1, isdisabled: false},
		{label: "Últimos 3 Días", value: 3, isdisabled: false},
		{label: "Últimos 5 Días", value: 5, isdisabled: false},
		{label: "Última Semana", value: 7, isdisabled: false},
		{label: "Último Mes", value: 30, isdisabled: false},
		{label: "Últimos 90 Días", value: 90, isdisabled: disableDate},
		{label: "Últimos 6 Meses", value: 180, isdisabled: disableDate},
		{label: "Último Año", value: 360, isdisabled: disableDate},
	];

	const validationItemsInitialState = {
		series_like: false,
		series_dislike: false,
		membership_purchase: false,
		content_like: false,
		content_dislike: false,
		content_views: false,
		series_views: false,
		trailer_views: false,
	};

	const series = useAppSelector(state => state.series.results);
	const isContentLoading = useAppSelector(state => state.serieContent.loading);
	const usersList = useAppSelector(state => state.marketing);
	const usersListLoading = useAppSelector(state => state.marketing.loading);
	const content = useAppSelector(state => state.serieContent.results);
	const dispatch = useAppDispatch();

	const fetchMarketingData = (email?: boolean, phone?: boolean) => {
		const params = buildParams("screen");
		if (selectedInteraction === "subscriptions") {
			dispatch(marketingActions.getSubscriptionsForMarketing({...params, validated_email: email, validated_phone: phone})).catch(err =>
				console.log(err),
			);
			return;
		}
		dispatch(marketingActions.getUsersForMarketing({...params, validated_email: email, validated_phone: phone}));
	};

	const [interactionFilter, setInteractionFilter] = useState<InteractionTypes>(null);
	const [selectionFilters, setSelectionFilters] = useState({
		serie: "",
		content: "",
		muxvideo: "",
		subscription: "",
		subscriptionType: "",
		subscriptionState: "",
	});
	const [isDisabledSelectionFilters, setIsDisabledSelectionFilters] = useState({
		serie: true,
		content: true,
		membership: true,
		date: true,
	});
	const [validationItems, setValidationItems] = useState(validationItemsInitialState);
	const [memberships, setMemberships] = useState<number>();
	const [dates, setSelectedDate] = useState<DateRange | undefined>();
	const [trailerLink, setTrailerLink] = useState<string | undefined>();
	const [isLocked, setIsLocked] = useState(true);
	const [isApplyDisabled, setIsApplyDisabled] = useState(true);
	const [open, setOpen] = useState(false);
	const [selectedInteraction, setSelectedInteraction] = useState<string | null>(null);
	const [sameInteractionCount, setSameInteractionCount] = useState(0);

	const selectPlaceholders = useMemo(() => {
		if (selectedInteraction !== "subscriptions") return null;
		return {
			subscription: "Tipo de Suscripción",
			subscriptionType: "Renovable",
			subscriptionState: "Estado de suscripción",
		};
	}, [selectedInteraction]);

	const options: SelectOptionType[] = useMemo(() => {
		if (!selectedInteraction) return [];
		if (selectedInteraction === "subscriptions") {
			return SUBSCRIPTIONS_TYPES.map(({label, value}) => ({label, value: String(value)}));
		}
		return series.map(serie => ({label: serie.title, value: serie.id}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [series.length, selectedInteraction]);

	const contentOptions: SelectOptionType[] = useMemo(() => {
		if (interactionFilter?.includes("content")) {
			return content
				.filter(content => content.content_type === 0 || content.content_type === 2)
				.map(content => ({label: content.title, value: content.id}));
		} else if (interactionFilter?.includes("trailer")) {
			return [
				...content.filter(content => content.content_type === 1).map(content => ({label: content.title, value: content.id})),
				{label: "Trailer de la serie", value: trailerLink || ""},
			];
		} else if (selectedInteraction === "subscriptions") {
			return [
				{
					label: "Autorrenovable",
					value: "1",
				},
				{
					label: "No autorenovable",
					value: "0",
				},
			];
		} else {
			return [];
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [content.length, interactionFilter, selectedInteraction]);

	const subscriptionsStateOptions = useMemo(() => {
		if (selectedInteraction === "subscriptions") {
			return [
				{
					label: "Activa",
					value: 1,
				},
				{
					label: "Inactiva",
					value: 0,
				},
			];
		}
		return [];
	}, [selectedInteraction]);

	useEffect(() => {
		if (interactionFilter) {
			setIsDisabledSelectionFilters({
				serie: false,
				content: true,
				membership: false,
				date: false,
			});
		}
	}, [interactionFilter]);

	useEffect(() => {
		dispatch(seriesActions.getSeriesList({params: {page_size: 999999}}));
	}, [dispatch]);

	useEffect(() => {
		if (
			selectionFilters.subscription ||
			(selectionFilters.serie && !interactionFilter?.includes("series") && !interactionFilter?.includes("membership"))
		) {
			setIsDisabledSelectionFilters({
				...isDisabledSelectionFilters,
				content: false,
			});
		}
	}, [isDisabledSelectionFilters, interactionFilter, selectionFilters.serie, selectionFilters.subscription]);

	function handleSelectInteraction(newValue: SingleValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) {
		if (newValue) {
			setInteractionFilter(newValue.value as InteractionTypes);

			if (newValue.value === selectedInteraction) {
				setSameInteractionCount(prevCount => {
					const newCount = prevCount + 1;
					return newCount;
				});
			} else {
				resetStates();
				setSelectedInteraction(newValue.value);
				setSameInteractionCount(1);
			}

			if (newValue.value.includes("views")) {
				setDisableDate(true);
			} else {
				setDisableDate(false);
			}

			if (newValue.value === "membership_purchase") {
				isApplyDisabled && setIsApplyDisabled(false);
			}

			setValidationItems(prevValidationItems => {
				const updatedValidationItems = {
					...prevValidationItems,
					[newValue.value]: true,
				};
				return updatedValidationItems;
			});
		}
	}

	function handleSelectSerie(newValue: SingleValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) {
		if (newValue) {
			if (selectedInteraction === "subscriptions") {
				setSelectionFilters({
					...selectionFilters,
					subscription: newValue.value,
				});
			} else {
				setSelectionFilters({
					...selectionFilters,
					serie: newValue.value,
				});
			}
		}
		const isValidInteractionFilter = interactionFilter?.includes("membership") || interactionFilter?.includes("subscriptions");
		if (
			selectedInteraction === "subscriptions" ||
			((interactionFilter?.includes("serie") || isValidInteractionFilter) && actionMeta.action !== "clear")
		) {
			isApplyDisabled && setIsApplyDisabled(false);
		}
	}

	function handleSelectDateFrame(newValue: SingleValue<SelectMembershipsType>, actionMeta: ActionMeta<SelectMembershipsType>) {
		if (newValue) {
			const datesInterval = DatesInterval(newValue.value);
			setSelectedDate(datesInterval);
		}
	}

	function handleSelectMembership(newValue: SingleValue<SelectMembershipsType>, actionMeta: ActionMeta<SelectMembershipsType>) {
		if (newValue) {
			if (selectedInteraction === "subscriptions") {
				setSelectionFilters(prev => ({
					...prev,
					subscriptionState: newValue.value.toString(),
				}));
			} else {
				setMemberships(newValue.value);
			}
			isApplyDisabled && setIsApplyDisabled(false);
		}
	}

	function handleSelectContent(newValue: SingleValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) {
		if (newValue) {
			if (newValue.label === "Trailer de la serie") {
				setSelectionFilters({
					content: "",
					serie: "",
					subscription: "",
					subscriptionType: "",
					subscriptionState: "",
					muxvideo: newValue.value,
				});
			} else if (selectedInteraction === "subscriptions") {
				setSelectionFilters(prev => ({
					...prev,
					subscriptionType: newValue.value,
				}));
			} else {
				setSelectionFilters({
					muxvideo: "",
					serie: "",
					subscription: "",
					subscriptionType: "",
					subscriptionState: "",
					content: newValue.value,
				});
			}
			isApplyDisabled && setIsApplyDisabled(false);
		}
	}

	const refresh = () => {
		setInteractionFilter(null);
		resetStates();
		dispatch(
			marketingActions.getUsersForMarketing({
				output: "screen",
				page: 0,
				page_size: pagination.pageSize,
				validated_email: true,
				validated_phone: true,
			}),
		);
	};

	const resetStates = () => {
		setSelectionFilters({
			serie: "",
			content: "",
			muxvideo: "",
			subscription: "",
			subscriptionType: "",
			subscriptionState: "",
		});
		setIsDisabledSelectionFilters({
			serie: true,
			content: true,
			membership: true,
			date: true,
		});
		setValidationItems(validationItemsInitialState);
		setMemberships(undefined);
		setSelectedDate(undefined);
		setIsLocked(true);
		setIsApplyDisabled(true);
	};

	function buildParams(output: "screen" | "file") {
		const seriesid = selectionFilters.content === "" && selectionFilters.serie !== "" ? selectionFilters.serie : undefined;
		if (selectedInteraction === "subscriptions") {
			const params: GetSubscriptionsListForMarketingParams = {
				output,
				page: pagination.pageIndex,
				page_size: pagination.pageSize,
				subscription_type: selectionFilters.subscription ? parseInt(selectionFilters.subscription) : undefined,
				subscription_renewal: selectionFilters.subscriptionType ? (selectionFilters.subscriptionType === "1" ? true : false) : undefined,
				active: selectionFilters.subscriptionState ? (selectionFilters.subscriptionState === "1" ? true : false) : undefined,
				initial_date: dates?.from ? formatISO9075(dates?.from!) : undefined,
				final_date: dates?.to ? formatISO9075(dates?.to!) : undefined,
			};
			return params;
		}

		const params: MarketingListParams = {
			output,
			page: pagination.pageIndex,
			page_size: pagination.pageSize,
			series_like: validationItems.series_like || undefined,
			series_dislike: validationItems.series_dislike || undefined,
			membership_purchase: validationItems.membership_purchase || undefined,
			content_like: validationItems.content_like || undefined,
			content_dislike: validationItems.content_dislike || undefined,
			views: validationItems.content_views || validationItems.series_views || validationItems.trailer_views || undefined,
			seriesid,
			contentid: selectionFilters.content !== "" ? selectionFilters.content : undefined,
			muxvideo_id: selectionFilters.muxvideo !== "" ? selectionFilters.muxvideo : undefined,
			initial_date: dates?.from ? formatISO9075(dates.from) : undefined,
			final_date: dates?.to ? formatISO9075(dates.to) : undefined,
			membership: memberships || memberships === 0 ? memberships : undefined,
			all: seriesid && validationItems.series_views ? true : undefined,
			admins: false,
		};

		return params;
	}

	const apply = (phone?: boolean, email?: boolean) => {
		// const params = buildParams("screen");
		setPagination({
			...pagination,
			pageIndex: 0,
		});
		fetchMarketingData(email, phone);
	};

	const handleUnlockApply = () => {
		isApplyDisabled && setIsApplyDisabled(false);
	};

	const handleUnlockCheckBox = () => {
		if (isLocked) {
			setOpen(!open);
		} else {
			setIsLocked(!isLocked);
		}
	};

	const handleIsLocked = () => {
		setIsLocked(!isLocked);
	};

	const handleIsOpen = () => {
		setOpen(!open);
	};

	useEffect(() => {
		selectionFilters.serie && dispatch(serieContentsActions.getSerieContents(selectionFilters.serie));

		series.forEach(serie => {
			if (serie.id === selectionFilters.serie) {
				setTrailerLink(serie.video_mux_id);
			}
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, selectionFilters.serie]);

	const DatesInterval = (days: number) => {
		const to = roundToNearestMinutes(endOfToday());
		const from = startOfDay(subDays(to, days));
		return {
			from: zonedTimeToUtc(from, timeZone),
			to: zonedTimeToUtc(to, timeZone),
		};
	};

	const handleExcelDownload = (email?: boolean, phone?: boolean) => {
		const params = buildParams("file");
		auth.currentUser
			?.getIdToken()
			.then(token => {
				if (selectedInteraction === "subscriptions") {
					getSubscriptionsListForMarketing(
						token,
						{
							...params,
							validated_email: email,
							validated_phone: phone,
							page: 0,
							page_size: 9999999,
						},
						true,
					)
						.then(res => {
							downloadFile(res, "MarketingDashboard");
						})
						.catch(err => {
							console.log(err);
						});
					return;
				}
				getUsersListForMarketing(
					token,
					{
						...params,
						validated_email: email,
						validated_phone: phone,
						page: 0,
						page_size: 9999999,
					},
					true,
				)
					.then(res => {
						downloadFile(res, "MarketingDashboard");
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => {
				console.log(err);
			});
	};

	return {
		interactionList,
		dateFrameList,
		interactionFilter,
		selectionFilters,
		isDisabledSelectionFilters,
		validationItems,
		memberships,
		isLocked,
		open,
		options,
		isContentLoading,
		contentOptions,
		refresh,
		handleSelectInteraction,
		handleSelectSerie,
		handleSelectDateFrame,
		handleSelectContent,
		handleUnlockApply,
		handleUnlockCheckBox,
		handleIsLocked,
		handleIsOpen,
		handleSelectMembership,
		usersListLoading,
		usersList,
		apply,
		isApplyDisabled,
		handleExcelDownload,
		sameInteractionCount,
		setPagination,
		pagination,
		fetchMarketingData,
		buildParams,
		subscriptionsStateOptions,
		selectPlaceholders,
	};
}
