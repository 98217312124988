import {useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {TopViews} from "../../../../components/blocks/Stats";
import {SponsorAdViews} from "../../../../components/blocks/Stats/SponsorAdViews";
import {SponsorAdViewsByAds} from "../../../../components/blocks/Stats/SponsorAdViewsByAds";
import {SponsorGoogleWorldChart} from "../../../../components/blocks/Stats/SponsorGoogleWorldChart";
import {userAuthActions} from "../../../../_store/features/user-auth/user-auth-slice";
import {SelectOptionType} from "../../../../../utils/globalTypes";
import {UserAuth} from "../../../../data-access/user-auth";
import {Spinner} from "../../../../components/primitives/icons";
import AppInstallationsChart from "../../../corporate-pages/users/_subcomponents/AppInstallationsChart";
import {UsersDemographicChart} from "../../../corporate-pages/users/_subcomponents/UsersDemographicChart";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {endOfToday, formatISO9075, roundToNearestMinutes, subYears} from "date-fns";
/* import {SponsorWorldChart} from "../../../../components/blocks/Stats/SponsorWorldChart"; */
import {DateRange} from "react-day-picker";
import {DatePickerWithRange} from "../../../../components/blocks/DateRangePicker";
import {dashboardActions} from "../../../../_store/features/dashboard/dashboard-slice";

interface SponsorAdsData {
	banner_ads:
		| {
				audit_created: string;
				audit_updated: string;
				author: string;
				id: string;
				img_mobile: string;
				img_web: string;
				order: number;
				prints: number;
				published: boolean;
				sponsor: string;
				sponsor_id: string;
				text: string;
				type_publicity: number;
		  }[]
		| null;
	total_payments: number;
	video_ads: any[] | null;
}

export function SponsorDashboard() {
	const dispatch = useAppDispatch();
	const currentUser = useAppSelector(state => state.user.userInfo);
	const [isLoading, setIsLoading] = useState(false);
	const [dates, setSelectedDate] = useState<DateRange>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = subYears(to, 1);
		return {
			from,
			to,
		};
	});
	const appInstallationsData = useDashboardByNameQuery("appInstallationsData");

	const [sponsorAdsData, setSponsorAdsData] = useState<Pick<UserAuth, "get_sponsor_data">>();
	useEffect(() => {
		if (!currentUser?.id) return;
		setIsLoading(true);
		dispatch(
			dashboardActions.getAppInstallations({
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
			}),
		);
		dispatch(
			userAuthActions.getSponsorAdsData({
				sponsorid: currentUser?.id,
			}),
		)
			.then(data => {
				const newData = data.payload as any;
				if (newData.get_sponsor_data) {
					setSponsorAdsData(newData);
				}
			})
			.finally(() => setIsLoading(false));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, dates]);

	const bannerOptions: SelectOptionType[] | undefined = useMemo(() => {
		if (!sponsorAdsData) return [];
		const options: any[] = [];

		const results = sponsorAdsData?.get_sponsor_data as unknown as SponsorAdsData;
		const videos = results.video_ads?.map(video => ({label: video.text, value: video.id}));
		const banners = results.banner_ads?.map(banner => ({label: banner.text, value: banner.id}));
		if (videos) options.concat(videos);
		if (banners) options.concat(banners);
		const sortedOptions = banners?.sort((a, b) => a.label.localeCompare(b.label));
		return sortedOptions;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sponsorAdsData?.get_sponsor_data?.banner_ads, sponsorAdsData?.get_sponsor_data?.video_ads]);

	const handleDateRangeChange = (s: number, e: number) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	return (
		<>
			<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Sponsor Dashboard</h2>
			</div>
			<DatePickerWithRange
				onDateRangeChange={handleDateRangeChange}
				date={dates}
				className="left-0 mt-5 w-80"
				disabled={{before: subYears(new Date(), 1), after: new Date()}}
			/>
			<div className="grid gap-6 px-8 py-6">
				<AppInstallationsChart dates={dates} data={appInstallationsData.data!} isLoading={appInstallationsData.isLoading} />
				<UsersDemographicChart dates={dates} />
				<SponsorAdViews sponsorid={currentUser?.userid} />
				<SponsorAdViewsByAds sponsorid={currentUser?.userid} />
				<TopViews type={"series"} />
				{isLoading ? (
					<div className="flex w-full justify-center">
						<Spinner />
					</div>
				) : (
					<SponsorGoogleWorldChart adsList={bannerOptions} />
				)}
				{/* <SponsorWorldChart /> */}
			</div>
		</>
	);
}
