import axios, {AxiosRequestConfig, AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface MarketingListResult {
	name: string;
	email: string;
	phone: string;
	userid: string;
}

export interface MarketingListParams {
	output: string;
	page: number;
	page_size: number;
	seriesid?: string;
	contentid?: string;
	membership?: number;
	initial_date?: string;
	final_date?: string;
	series_views?: string;
	trailer_views?: string;
	content_views?: string;
	series_like?: boolean;
	series_dislike?: boolean;
	membership_purchase?: boolean;
	content_like?: boolean;
	content_dislike?: boolean;
	validated_phone?: boolean;
	validated_email?: boolean;
	views?: boolean;
	muxvideo_id?: string;
	all?: boolean;
	admins?: boolean;
}

type MarketingRequestConfig = {
	headers: {
		"Content-Type": string;
		"Content-Disposition": string;
		Accept: string;
		Authorization: string;
	};
	params?: MarketingListParams;
	responseType?: "arraybuffer" | "blob" | "document" | "json" | "text" | undefined;
};

export async function getUsersListForMarketing(token: string, params?: MarketingListParams, useResponseType: boolean = false) {
	const idToken = `Bearer ${token}`;
	const headers = {
		"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"Content-Disposition": "attachment; filename=marketing.xlsx",
		Accept: "*/*",
		Authorization: idToken,
	};

	const config: AxiosRequestConfig & MarketingRequestConfig = {
		headers,
		params,
	};

	if (useResponseType) {
		config.responseType = "blob";
	}

	const response = await axiosHandler.get("/marketing_query_data", config);

	return response.data;
}

export interface GetSubscriptionsListForMarketingParams {
	page_size: number;
	page: number;
	output: string;
	platform?: boolean;
	subscription_type?: number;
	subscription_renewal?: boolean;
	renewal?: boolean;
	active?: boolean;
	initial_date?: string;
	final_date?: string;
	validated_email?: boolean;
	validated_phone?: boolean;
}

export interface GetSubscriptionsListForMarketingResponse {
	user_name: string;
	user_id: string;
	product_series_name: string;
	referral_code: string;
	created_at: string;
	renewed: boolean;
	subscription_type: number;
	price: number;
	fee: number;
	platform: string;
}

export async function getSubscriptionsListForMarketing(
	token: string,
	params?: GetSubscriptionsListForMarketingParams,
	useResponseType: boolean = false,
) {
	const idToken = `Bearer ${token}`;
	const headers = {
		"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"Content-Disposition": "attachment; filename=marketing.xlsx",
		Accept: "*/*",
		Authorization: idToken,
	};

	const config: AxiosRequestConfig & MarketingRequestConfig = {
		headers,
		params,
	};

	if (useResponseType) {
		config.responseType = "blob";
	}

	const response = await axiosHandler.get("/payments_query_data", config);

	return response.data;
}

export interface GetSeriesViewsByUserParams {
	serie_id?: string;
	not_serie_id?: string;
	content_id?: string;
	not_content_id?: string;
	content_id_list?: string;
	not_content_id_list?: string;
	email_verified?: boolean;
	phone_verified?: boolean;
	initial_date?: string;
	final_date?: string;
	page?: number;
	page_size?: number;
	output_excel?: boolean;
}

export interface UsersBySeriesViews {
	email: string;
	email_verified: boolean;
	name: string;
	phone: string;
	phone_verified: boolean;
	user_id: string;
	view_count: number;
}

export interface GetSeriesViewsByUserResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: UsersBySeriesViews[];
}

export const getSeriesViewsByUser = async (
	token: string,
	params?: GetSeriesViewsByUserParams,
	responseType: boolean = false,
): Promise<AxiosResponse<GetSeriesViewsByUserResponse>> => {
	const idToken = `Bearer ${token}`;
	const headers = {
		"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"Content-Disposition": "attachment; filename=marketing.xlsx",
		Accept: "*/*",
		Authorization: idToken,
	};
	const config: AxiosRequestConfig & GetSeriesViewsByUserParams = {
		headers,
		params,
	};

	if (responseType) {
		config.responseType = "blob";
		const response = await axiosHandler.get("/get_series_views_by_user", config);
		return response;
	}

	return await axiosHandler.get("/get_series_views_by_user", config);
};

export type ProcessContactsParams = {
	file?: File;
	tags: string;
	email_list?: string;
	token: string;
};

type ProcessContactsResponse = {
	result?: "success";
	error?: "Invalid parameters" | "Unauthorized";
};

export const processContacts = async ({token, file, tags, email_list}: ProcessContactsParams): Promise<AxiosResponse<ProcessContactsResponse>> => {
	const idToken = `Bearer ${token}`;
	const headers = {
		"Content-Type": "multipart/form-data",
		Accept: "*/*",
		Authorization: idToken,
	};

	const formData = new FormData();

	formData.append("tags", tags);
	if (email_list) {
		formData.append("email_list", email_list);
	}
	if (file) {
		formData.append("file", file);
	}

	const config: AxiosRequestConfig = {
		headers,
		method: "POST",
		data: formData,
	};

	return await axiosHandler.post("/import_tag_list_user", formData, config);
};

export type CancelledSubscriptionUsersParams = {
	page_size?: number;
	page?: number;
	product_id?: string;
	platform?: PlatformsNames;
	initial_termination_date?: string;
	final_termination_date?: string;
	initial_susbcription_date?: string;
	final_susbcription_date?: string;
	export_csv?: boolean;
};

export type CancelledSubscriptionUser = {
	userid: string;
	email: string;
	product_name: string;
	subscription_date: string;
	termination_date: string;
	platform?: PlatformsNames;
};

export type PlatformsNames = "STRIPE" | "GOOGLE" | "APPLE" | "PROSPAY" | "ADMIN" | "UNKNOWN";

type CancelledSubscriptionUsersResponse = {
	totalResults: number;
	pageSize: number;
	current: number;
	results: CancelledSubscriptionUser[];
};

export const getListCancelledSubscriptionUsers = async ({
	token,
	params,
}: {
	token: string;
	params: CancelledSubscriptionUsersParams;
}): Promise<AxiosResponse<CancelledSubscriptionUsersResponse>> => {
	const idToken = `Bearer ${token}`;

	const response = await axiosHandler.get("/list_cancelled_subscription_users", {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});

	return response;
};
