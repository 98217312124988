import {useEffect, useMemo, useState} from "react";
import {Spinner} from "../../../../components/primitives/icons";
import {GeneralIncome} from "../../../../components/blocks/Stats/GeneralIncome";
import Select, {ActionMeta, SingleValue} from "react-select";
import {selectStyles} from "../../../../../utils/SelectStyles";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {seriesActions} from "../../../../_store/features/series/series-slice";
import {DatePickerWithRange} from "../../../../components/blocks/DateRangePicker";
import {DateRange} from "react-day-picker";
import {endOfToday, formatISO9075, roundToNearestMinutes, subYears} from "date-fns";
import {PeriodIncome} from "../../../../components/blocks/Stats/PeriodIncome";
import CoinEarningsTable from "../marketing/_subcomponents/CoinEarningsTable";
import IncomeVsProfit from "../../../../components/blocks/Stats/IncomeVsProfit/IncomeVsProfit";
import {Button} from "../../../../components/primitives/Button";
import {useNavigate} from "react-router-dom";
import {MembershipCoinsIncome} from "../../../../components/blocks/Stats/MembershipCoinsIncome";
import {dashboardActions} from "../../../../_store/features/dashboard/dashboard-slice";
import {dashboardCorporateActions} from "../../../../_store/features/dashboard-corporate/dashboard-corporate-slice";
import PartnerSeriesViews from "../../../../components/blocks/Stats/PartnerSeriesViews";
import {LikesAndFavorites} from "../../../../components/blocks/Stats";
import {SelectOptionType} from "../../../../../utils/globalTypes";
import SeriesRankingResume from "./subcomponents/SeriesRankingResume";
import ContentRankingResume from "./subcomponents/ContentRankingResume";
import FreeSubscriptionsChart from "./subcomponents/FreeSubscriptionsChart";
import ReproductionTimeDataChart from "./subcomponents/ReproductionTimeDataChart";
import SubscriptionSlotsVariation from "./subcomponents/SubscriptionSlotsVariation";

export default function CorporateDashboard() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const series = useAppSelector(state => state.series.results);
	const isSerieLoading = useAppSelector(state => state.series.loading);
	const [titleLabel, setTitleLabel] = useState("GENERAL DE LA PLATAFORMA");
	const [serieId, setSerieId] = useState<string | undefined>();
	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = subYears(to, 1);
		return {from, to};
	});

	useEffect(() => {
		dispatch(seriesActions.getSeriesList({params: {page: 0, page_size: 99999999}}));
	}, [dispatch]);

	useEffect(() => {
		dispatch(
			dashboardCorporateActions.getGeneralIncomeAndProfitV2({
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
				seriesid: serieId,
			}),
		);
		dispatch(
			dashboardActions.getAssignedFreeSubscriptionsByUser({
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
			}),
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates, serieId]);

	const seriesOptions: SelectOptionType[] = useMemo(() => {
		const options = series.map(serie => ({label: serie.title, value: serie.id}));
		options.unshift({label: "GENERAL DE LA PLATAFORMA", value: ""});
		return options;
	}, [series]);

	const handleSelectContent = (newValue: SingleValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) => {
		if (!newValue) return;
		setTitleLabel(newValue.label);
		if (newValue.value !== "") setSerieId(newValue.value);
		else setSerieId(undefined);
	};

	const handleDateRangeChange = (s: number, e: number, selectedDate?: DateRange) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	return (
		<div className="flex h-screen flex-col">
			{isSerieLoading ? (
				<div className="flex h-screen items-center justify-center">
					<Spinner />
				</div>
			) : (
				<>
					<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
						<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">{`Información - ${titleLabel}`}</h2>
						<Select
							className="basic-select mx-4 my-7 w-60 text-sm"
							styles={selectStyles}
							isSearchable={true}
							options={seriesOptions}
							classNamePrefix="select"
							onChange={handleSelectContent}
							placeholder="Contenido"
							isLoading={isSerieLoading}
						/>
					</div>

					<div className="flex flex-col gap-3 py-6">
						<div className="flex w-full flex-row justify-between">
							<DatePickerWithRange
								onDateRangeChange={handleDateRangeChange}
								date={dates}
								className="left-0 w-80"
								disabled={{before: subYears(new Date(), 1), after: new Date()}}
							/>
							<Button
								type="button"
								variant="blueBtn"
								size="lg"
								className="mr-8 w-60 py-1.5"
								onClick={() => navigate("/corporate/purchases")}
							>
								Ver todas las compras
							</Button>
						</div>
						<div className="flex flex-col gap-3 px-8 py-6">
							<GeneralIncome serieId={serieId} dates={dates} />
							{serieId ? (
								<>
									<PeriodIncome serieId={serieId} dates={dates} />
									<PartnerSeriesViews serieid={serieId} showCard />
									<LikesAndFavorites seriesIds={serieId} isCorporate dates={dates} showTotalCard />
									<CoinEarningsTable serieid={serieId} dates={dates} />
								</>
							) : (
								<>
									<IncomeVsProfit dates={dates} />
									<MembershipCoinsIncome dates={dates} />
									<SubscriptionSlotsVariation dates={dates} />
									<FreeSubscriptionsChart dates={dates} />
									<ReproductionTimeDataChart dates={dates} />
									<div className="flex flex-col gap-7 2xl:flex-row">
										<SeriesRankingResume dates={dates} />
										<ContentRankingResume dates={dates} />
									</div>
								</>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
}
