import {PayloadAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
	CancelledSubscriptionUser,
	CancelledSubscriptionUsersParams,
	GetSeriesViewsByUserParams,
	GetSubscriptionsListForMarketingParams,
	MarketingListParams,
	MarketingListResult,
	UsersBySeriesViews,
	getListCancelledSubscriptionUsers,
	getSeriesViewsByUser,
	getSubscriptionsListForMarketing,
	getUsersListForMarketing,
	processContacts,
} from "../../../data-access/marketing/marketing";
import {auth} from "../../../firebase";

export interface marketingInterface {
	loading: boolean;
	results: MarketingListResult[] | UsersBySeriesViews[] | CancelledSubscriptionUser[];
	totalResults: number;
	pageSize: number;
	current: number;
	error: string;
	success: boolean;
}

const initialState: marketingInterface = {
	loading: false,
	results: [],
	totalResults: 0,
	pageSize: 0,
	current: 0,
	success: false,
	error: "",
};

const getUsersForMarketing = createAsyncThunk("marketing/list", async (params: MarketingListParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getUsersListForMarketing(token, params);

		return {
			totalResults: response.totalResults,
			pageSize: response.pageSize,
			current: response.current,
			results: response.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getSubscriptionsForMarketing = createAsyncThunk(
	"marketing/subscriptions-list",
	async (params: GetSubscriptionsListForMarketingParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await getSubscriptionsListForMarketing(token, params);

			return {
				totalResults: response.totalResults,
				pageSize: response.pageSize,
				current: response.current,
				results: response.results,
			};
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getSeriesViewsByUserAction = createAsyncThunk(
	"marketing/get-users-by-series-views",
	async (params: GetSeriesViewsByUserParams | undefined, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await getSeriesViewsByUser(token, params);

			return {
				totalResults: response.data.totalResults,
				pageSize: response.data.pageSize,
				current: response.data.current,
				results: response.data.results,
			};
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const processOdooContactTags = createAsyncThunk("marketing/process-contacts-tags", async (params: {file: File; tags: string}, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await processContacts({token: token, file: params.file, tags: params.tags});

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const listCancelledSubscriptionUsers = createAsyncThunk(
	"marketing/list-cancelled-subscription-users",
	async (params: CancelledSubscriptionUsersParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = (await getListCancelledSubscriptionUsers({token, params})).data;

			return {
				totalResults: response.totalResults,
				pageSize: response.pageSize,
				current: response.current,
				results: response.results,
			};
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const processOdooContactTagsList = createAsyncThunk(
	"marketing/process-contacts-tags-list",
	async (params: CancelledSubscriptionUsersParams & {tags: string[]}, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return thunkAPI.rejectWithValue("No User Auntehticated");

			const responseList = await getListCancelledSubscriptionUsers({token, params});
			const emails = responseList.data.results.map(user => user.email).toString();
			const response = await processContacts({token: token, email_list: emails, tags: params.tags.toString()});
			if (response.status === 200) {
				return {};
			} else {
				return thunkAPI.rejectWithValue("Problem processing tags");
			}
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem processing tags");
		}
	},
);

const marketingSlice = createSlice({
	name: "marketing",
	initialState,
	reducers: {
		setSuccess(state, action: PayloadAction<boolean>) {
			state.success = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getUsersForMarketing.pending, state => {
				state.loading = true;
			})
			.addCase(getUsersForMarketing.fulfilled, (state, action) => {
				state.loading = false;
				state.results = action.payload?.results ?? [];
				state.totalResults = action.payload?.totalResults!;
				state.pageSize = action.payload?.pageSize!;
			})
			.addCase(getUsersForMarketing.rejected, (state, action) => {});
		builder
			.addCase(getSeriesViewsByUserAction.pending, state => {
				state.loading = true;
			})
			.addCase(getSeriesViewsByUserAction.fulfilled, (state, action) => {
				state.loading = false;
				state.results = action.payload?.results ?? [];
				state.totalResults = action.payload?.totalResults!;
				state.pageSize = action.payload?.pageSize!;
			})
			.addCase(getSeriesViewsByUserAction.rejected, (state, action) => {});
		builder
			.addCase(getSubscriptionsForMarketing.pending, state => {
				state.loading = true;
			})
			.addCase(getSubscriptionsForMarketing.fulfilled, (state, action) => {
				state.loading = false;
				state.results = action.payload?.results ?? [];
				state.totalResults = action.payload?.totalResults!;
				state.pageSize = action.payload?.pageSize!;
			})
			.addCase(getSubscriptionsForMarketing.rejected, (state, action) => {});
		builder
			.addCase(processOdooContactTags.pending, state => {
				state.loading = true;
			})
			.addCase(processOdooContactTags.fulfilled, state => {
				state.loading = false;
			})
			.addCase(processOdooContactTags.rejected, state => {
				state.loading = false;
			});
		builder
			.addCase(listCancelledSubscriptionUsers.pending, state => {
				state.loading = true;
			})
			.addCase(listCancelledSubscriptionUsers.fulfilled, (state, action) => {
				state.loading = false;
				state.results = action.payload?.results ?? [];
				state.totalResults = action.payload?.totalResults!;
				state.pageSize = action.payload?.pageSize!;
			})
			.addCase(listCancelledSubscriptionUsers.rejected, (state, action) => {});
		builder
			.addCase(processOdooContactTagsList.pending, state => {
				state.loading = true;
			})
			.addCase(processOdooContactTagsList.fulfilled, state => {
				state.loading = false;
			})
			.addCase(processOdooContactTagsList.rejected, state => {
				state.loading = false;
			});
	},
});
export const marketingActions = {
	...marketingSlice.actions,
	getUsersForMarketing,
	getSeriesViewsByUserAction,
	getSubscriptionsForMarketing,
	processOdooContactTags,
	listCancelledSubscriptionUsers,
	processOdooContactTagsList,
};

export default marketingSlice;
