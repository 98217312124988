import {BarElement, CategoryScale, ChartData, Chart as ChartJS, Tooltip as ChartJSTooltip, ChartOptions, LinearScale} from "chart.js";
import "chartjs-adapter-date-fns";
import {differenceInDays, endOfToday, format, formatISO9075, parse, roundToNearestMinutes, startOfDay, subDays, subYears} from "date-fns";
import {useEffect, useMemo, useRef, useState} from "react";
import {Bar} from "react-chartjs-2";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {useAppDispatch} from "../../../../_store/hooks";
import {dashboardActions} from "../../../../_store/features/dashboard/dashboard-slice";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {Card} from "../Card";
import {Spinner} from "../../../primitives/icons";
import {DatePickerWithRange} from "../../DateRangePicker";
import {DateRange} from "react-day-picker";
import {es} from "date-fns/locale";
import {getChartData, getGroupedByDay, getMonthlyProfitData, oneMonth} from "../../../../../utils/barGraphTransformData";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartJSTooltip);

export const options: ChartOptions<"bar"> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		tooltip: {
			callbacks: {
				title(tooltipItems) {
					return format(new Date(tooltipItems[0].label), "EE, dd MMMM", {locale: es});
				},
				label: function (context) {
					let label = context.dataset.label || "";

					if (label) {
						label += ": ";
					}
					if (context.parsed.y !== null) {
						label += new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(context.parsed.y);
					}
					return label;
				},
			},
		},
	},
	scales: {
		x: {
			ticks: {
				callback(tickValue) {
					return format(new Date(this.getLabelForValue(tickValue as any)), "MMM d").toLowerCase();
				},
			},
			grid: {
				color: "transparent",
			},
		},
		y: {
			ticks: {
				callback(tickValue) {
					return new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(Number(tickValue) ?? 0);
				},
			},
		},
	},
};

interface SerieIncomeAndProfitProps {
	seriesidList: string;
	partnerSeries: {
		active: boolean;
		production_cost: number;
		profit: number;
		serieid: string;
		title: string;
	}[];
}

export function SerieIncomeAndProfit({seriesidList, partnerSeries}: SerieIncomeAndProfitProps) {
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; profit: number}[]>>(null);
	const {data: statistics, isLoading} = useDashboardByNameQuery("partnerSeriesIncome");
	const [showByMonths, setShowByMonths] = useState(false);
	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = startOfDay(subDays(Date.now(), 30));
		return {
			from,
			to,
		};
	});

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (seriesidList !== "") {
			const durationInMilliseconds = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
			setShowByMonths(durationInMilliseconds > oneMonth);
			dispatch(
				dashboardActions.getInfluencerAndPartnerTransactions({
					seriesid_list: seriesidList,
					initial_date: formatISO9075(dates?.from!),
					final_date: formatISO9075(dates?.to!),
					all: true,
				}),
			);
			dispatch(
				dashboardActions.getPartnersSeriesProfit({
					seriesid_list: seriesidList,
					initial_date: formatISO9075(dates?.from!),
					final_date: formatISO9075(dates?.to!),
				}),
			);
		}
	}, [dates, dispatch, seriesidList]);

	const data: ChartData<"bar", {date: string; profit: number}[]> = useMemo(() => {
		const startDate = new Date(dates?.from ?? 0);
		const endDate = new Date(dates?.to ?? Date.now());
		const diff_days = differenceInDays(endDate, startDate) + 1;

		//Transforms the data returned from big query
		const membershipData =
			statistics?.daily_profit?.map(({date, profit}) => ({
				date: new Date(date).toISOString(),
				profit: profit * 0.01,
			})) ?? [];
		const rentalsData =
			statistics?.daily_profit_rentals?.map(({date, profit}) => ({
				date: new Date(date).toISOString(),
				profit: profit * 0.01,
			})) ?? [];

		// Group elements by day
		const membershipDataGrouped = getGroupedByDay(membershipData);
		const rentalsDataGrouped = getGroupedByDay(rentalsData);

		//Combined data from statistics and filled.
		const membershipChartData = getChartData(membershipDataGrouped, diff_days, endDate);
		const rentalsChartData = getChartData(rentalsDataGrouped, diff_days, endDate);

		// Group elements by month
		const membershipMonthlyProfitData = getMonthlyProfitData(membershipDataGrouped);
		const rentalsMonthlyProfitData = getMonthlyProfitData(rentalsDataGrouped);

		const currentDate = new Date(startDate);
		while (currentDate <= endDate) {
			const year = currentDate.getFullYear();
			const month = currentDate.getMonth() + 1;
			const monthString = month < 10 ? `0${month}` : `${month}`;
			const key = `${year}-${monthString}`;

			if (!membershipMonthlyProfitData[key]) {
				membershipMonthlyProfitData[key] = {
					date: `${year}-${monthString}`,
					profit: 0,
				};
			}
			if (!rentalsMonthlyProfitData[key]) {
				rentalsMonthlyProfitData[key] = {
					date: `${year}-${monthString}`,
					profit: 0,
				};
			}

			currentDate.setMonth(currentDate.getMonth() + 1);
		}

		const membershipMonthlyDataArray = Object.values(membershipMonthlyProfitData);
		const rentalsMonthlyDataArray = Object.values(rentalsMonthlyProfitData);

		membershipMonthlyDataArray.sort((a, b) => {
			const dateA = parse(a.date, "yyyy-MM", new Date());
			const dateB = parse(b.date, "yyyy-MM", new Date());

			return dateA.getTime() - dateB.getTime();
		});
		rentalsMonthlyDataArray.sort((a, b) => {
			const dateA = parse(a.date, "yyyy-MM", new Date());
			const dateB = parse(b.date, "yyyy-MM", new Date());

			return dateA.getTime() - dateB.getTime();
		});

		const membershipSelectedData = showByMonths ? membershipMonthlyDataArray : membershipChartData;
		const rentalsSelectedData = showByMonths ? rentalsMonthlyDataArray : rentalsChartData;

		return {
			datasets: [
				{
					label: "Membresías",
					data: membershipSelectedData,
					backgroundColor: "#005096",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "profit",
					},
				},
				{
					label: "Rentas",
					data: rentalsSelectedData,
					backgroundColor: "#f5ab00",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "profit",
					},
				},
			],
		};
	}, [dates?.from, dates?.to, showByMonths, statistics?.daily_profit, statistics?.daily_profit_rentals]);

	const getWeightedPercentage = () => {
		if (!partnerSeries) return 0;
		if (partnerSeries.length < 2) return partnerSeries[0].profit;
		const activeSeries = statistics?.series_profit_list
			?.filter(s => s.active)
			.map(item => {
				const serieProductionCost = partnerSeries.find(i => i.serieid === item.seriesid)?.production_cost ?? 0;
				return {
					profit: item.total_profit - serieProductionCost,
					percentage: item.profit_percentage,
				};
			});
		return activeSeries?.reduce((acc, curr) => acc + curr.profit * 0.01 * (curr.percentage * 0.01), 0) ?? 0;
	};

	const incomeData = useMemo(() => {
		const selectedSeriesList = seriesidList.split(",");
		const selectedSeries = partnerSeries.filter((item: any) => selectedSeriesList.some(i => i === item.serieid));
		const totalProdCost = selectedSeries.reduce((acc, curr) => acc + (curr?.production_cost ?? 0), 0);
		const totalProfit = getWeightedPercentage();
		const totalSerieIncome = ((statistics?.total_profit ?? 0) + (statistics?.total_profit_rentals ?? 0)) * 0.01;
		const netTotalIncome = ((statistics?.total_profit ?? 0) + (statistics?.total_profit_rentals ?? 0) - totalProdCost) * 0.01;
		const totalIncomeLastMonth = ((statistics?.range_profit ?? 0) + (statistics?.range_profit_rentals ?? 0)) * 0.01;

		const totalPartnerIncome = netTotalIncome > 0 ? totalProfit : 0;

		return {totalSerieIncome, totalIncomeLastMonth, netTotalIncome, totalPartnerIncome};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [partnerSeries, statistics, seriesidList]);

	const handleDateRangeChange = (s: number, e: number, selectedDate?: DateRange) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 px-8 pt-6 md:grid-cols-2 lg:grid-cols-7">
			<div className="flex w-80 flex-row">
				<DatePickerWithRange
					onDateRangeChange={handleDateRangeChange}
					disabled={{before: subYears(new Date(), 1), after: new Date()}}
					date={dates}
				/>
			</div>
			<div className="col-span-7 grid grid-cols-2 gap-4 overflow-y-auto xl:grid-cols-4">
				<Card
					title="Ingreso Personal"
					amount={incomeData.totalPartnerIncome}
					tooltip="Distribución de las ganancias personales."
					loading={isLoading}
				/>
				<Card
					title="Ingreso Neto"
					amount={incomeData.netTotalIncome}
					tooltip="Dinero generado por la serie luego de deducidos los costos de producción."
					loading={isLoading}
				/>
				<Card
					title="Ingresos Totales"
					amount={incomeData.totalSerieIncome}
					tooltip="Ingresos totales obtenidos por la serie desde su estreno."
					loading={isLoading}
				/>
				<Card
					title="Total del período"
					amount={incomeData.totalIncomeLastMonth}
					tooltip="Total generado por la serie en los últimos 30 días."
					loading={isLoading}
				/>
			</div>
			<div className="col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Ingreso del período.</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<div className="relative p-6 pt-0">
						<Bar
							height={350}
							ref={chartRef}
							options={{
								...options,
								scales: {
									x: {
										ticks: {
											callback(tickValue) {
												const date = new Date(this.getLabelForValue(tickValue as any));
												const newMonthlyDate = new Date(date.getFullYear(), date.getMonth() + 1);

												// Format the date based on the available information
												const formattedDate = showByMonths
													? format(newMonthlyDate, "yyyy - MMM", {locale: es}).toLowerCase()
													: format(date, "MMM d", {locale: es}).toLowerCase();

												return formattedDate;
											},
										},
										grid: {
											color: "transparent",
										},
										stacked: true,
									},
									y: {
										stacked: true,
									},
								},
							}}
							data={data}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
