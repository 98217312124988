import "chartjs-adapter-date-fns";
import {formatISO9075, subYears} from "date-fns";
import {useEffect, useMemo, useRef, useState} from "react";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {useAppDispatch} from "../../../../../_store/hooks";
import {useDashboardByNameQuery} from "../../../../../_store/features/dashboard/hooks";
import {dashboardActions} from "../../../../../_store/features/dashboard/dashboard-slice";
import {Card} from "../../../../../components/blocks/Stats/Card";
import {Spinner, UserFilled} from "../../../../../components/primitives/icons";
import {DatePickerWithRange} from "../../../../../components/blocks/DateRangePicker";
import {DateRange} from "react-day-picker";
import {oneMonth} from "../../../../../../utils/barGraphTransformData";
import InfluencerBarChart from "../../../../../components/blocks/Stats/InfluencerBarChart/index";
import {useNavigate} from "react-router-dom";
import {Skeleton} from "../../../../../components/primitives/Skeleton";

interface SerieIncomeAndProfitProps {
	userid?: string;
	influencerReferralCode: string | undefined;
	influencerReferUsersCount: number | undefined;
	currentSeriesId: string;
	dates: DateRange | undefined;
	setSelectedDate: (value: DateRange | undefined) => void;
	loading?: boolean;
}
export function InfluencerChartIncome({
	userid,
	influencerReferralCode,
	influencerReferUsersCount,
	dates,
	currentSeriesId,
	setSelectedDate,
}: SerieIncomeAndProfitProps) {
	const dispatch = useAppDispatch();
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; value: number}[]>>(null);
	const influencers = useDashboardByNameQuery("influencerProfitByContent");
	const {data: statistics, isLoading: isStatisticsLoading} = useDashboardByNameQuery("influencerIncome");
	const [showByMonths, setShowByMonths] = useState(false);
	const [loading, setLoading] = useState(true);

	const navigate = useNavigate();

	const general_percentage = useMemo(() => {
		const weightedPercentage = statistics?.weighted_percentage ? statistics.weighted_percentage * 0.01 : 0.5;
		return weightedPercentage;
	}, [statistics]);

	useEffect(() => {
		if (!influencers.data) return navigate(-1);
		if (!currentSeriesId) return;

		const durationInMilliseconds = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
		setShowByMonths(durationInMilliseconds > oneMonth);

		dispatch(
			dashboardActions.getInfluencerSerieIncomeAndProfit({
				seriesid_list: currentSeriesId,
				referral_code: influencerReferralCode || "",
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
			}),
		).then(() => setLoading(false));

		return () => {
			setLoading(true);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates, currentSeriesId]);

	const handleDateRangeChange = (s: number, e: number, selectedDate?: DateRange) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	const total_profit = useMemo(() => {
		const membershipProfit = statistics?.historic_membership_profit ? statistics.historic_membership_profit : 0;
		const subscriptionProfit = statistics?.historic_subscription_profit ? statistics.historic_subscription_profit : 0;
		const total = membershipProfit * 0.01 + subscriptionProfit * 0.01;

		return total;
	}, [statistics]);

	const total_fee = useMemo(() => {
		const membershipFee = statistics?.historic_membership_fee ? statistics.historic_membership_fee : 0;
		const subscriptionFee = statistics?.historic_subscription_fee ? statistics.historic_subscription_fee : 0;
		const total = membershipFee * 0.01 + subscriptionFee * 0.01;

		return total;
	}, [statistics]);

	const net_total = useMemo(() => {
		return total_profit - total_fee;
	}, [total_fee, total_profit]);

	const historic_membership_profit = useMemo(() => {
		const membershipProfit = statistics?.historic_membership_profit ? statistics.historic_membership_profit : 0;
		const membershipFee = statistics?.historic_membership_fee ? statistics.historic_membership_fee : 0;
		const total = membershipProfit * 0.01 - membershipFee * 0.01;

		return total;
	}, [statistics]);

	const historic_subscription_profit = useMemo(() => {
		const subscriptionProfit = statistics?.historic_subscription_personal_profit ? statistics.historic_subscription_personal_profit * 0.01 : 0;

		return subscriptionProfit;
	}, [statistics]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 md:grid-cols-2 lg:grid-cols-7">
			<div className="flex w-80 flex-row">
				<DatePickerWithRange
					onDateRangeChange={handleDateRangeChange}
					disabled={{before: subYears(new Date(), 1), after: new Date()}}
					date={dates}
				/>
			</div>
			<div className="col-span-7 grid grid-cols-2 gap-4 overflow-y-auto xl:grid-cols-4">
				<Card
					title="Total de ingreso Bruto"
					amount={influencerReferralCode ? total_profit : 0}
					loading={total_profit === 0 && isStatisticsLoading}
					tooltip="Ingresos totales obtenidos por referidos y suscripciones sin descontar fees de las tiendas."
				/>
				<Card
					title="Total de ingreso Neto"
					amount={influencerReferralCode ? net_total : 0}
					loading={net_total === 0 && isStatisticsLoading}
					tooltip="Ingresos totales obtenidos por referidos y suscripciones después de descontados los fees de las tiendas."
				/>
				<Card
					title="Total de ganancia del Asociado"
					amount={influencerReferralCode ? historic_subscription_profit + historic_membership_profit * general_percentage : 0}
					loading={historic_subscription_profit + historic_membership_profit * general_percentage === 0 && isStatisticsLoading}
					tooltip="Ganancias netas del asociado obtenida por referidos y suscripciones."
				/>
				<Card
					title="Total de ganancia de la Plataforma"
					amount={influencerReferralCode ? net_total - (historic_subscription_profit + historic_membership_profit * general_percentage) : 0}
					loading={
						net_total - (historic_subscription_profit + historic_membership_profit * general_percentage) === 0 && isStatisticsLoading
					}
					tooltip="Ganancias netas de la plataforma a partir de los referidos del asociado."
				/>
			</div>
			<div className="col-span-full flex w-full items-center justify-start gap-4 rounded-md border border-border p-6">
				<div className="mr-2 aspect-square rounded-lg bg-foreground p-2 text-secondary">
					<UserFilled className="h-4 w-4" />
				</div>
				<div className="flex items-center gap-2 text-lg font-semibold">
					{influencerReferralCode && influencerReferUsersCount !== undefined ? (
						<>
							<span>Referidos registrados:</span>
							<span>{influencerReferUsersCount}</span>
						</>
					) : (
						<>
							{!influencerReferralCode && !loading ? (
								<h1 className="ext-lg text-xl font-semibold leading-none tracking-tight text-warning">
									Asociado sin código de referido
								</h1>
							) : (
								<Skeleton className="h-12 w-72 bg-slate-300" />
							)}
						</>
					)}
				</div>
			</div>
			<div className="col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Ganancias por compras de los referidos.</h3>
				</div>
				{isStatisticsLoading || loading ? (
					<div className="flex h-[400px] flex-col items-center justify-center">
						{!influencerReferralCode && !loading ? (
							<h1 className="ext-lg text-xl font-semibold leading-none tracking-tight text-warning">Asociado sin código de referido</h1>
						) : (
							<Spinner />
						)}
					</div>
				) : (
					<InfluencerBarChart chartRef={chartRef} dates={dates} showByMonths={showByMonths} percentage={general_percentage} />
				)}
			</div>
		</div>
	);
}
