import {useEffect, useMemo, useState} from "react";
import {BarsPlus} from "../../../components/primitives/icons";
import {DatePickerWithRange} from "../../../components/blocks/DateRangePicker";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {DataTableColumnHeader} from "../../../components/primitives/DataTable";
import {cn} from "../../../../utils/classNames";
import {Button} from "../../../components/primitives/Button";
import {Link} from "react-router-dom";
import {DataTable} from "../../../components/blocks/DataTable";
import {SeriesRanking, seriesRankingActions} from "../../../_store/features/series/series-ranking-slice";
import {endOfToday, formatISO9075, roundToNearestMinutes, subYears} from "date-fns";
import {DateRange} from "react-day-picker";

const columnHelper = createColumnHelper<SeriesRanking>();

export default function RankedSeries() {
	const dispatch = useAppDispatch();
	const {results: data, loading: isLoading, totalResults, pageSize} = useAppSelector(state => state.seriesRanking);
	const [page, setPage] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = subYears(to, 1);
		return {
			from,
			to,
		};
	});
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	function getRankingResume() {
		dispatch(
			seriesRankingActions.getSeriesListRanking({
				final_date: formatISO9075(dates?.to!),
				initial_date: formatISO9075(dates?.from!),
				page: page.pageIndex,
				page_size: page.pageSize,
			}),
		);
	}

	useEffect(() => {
		if (!page.pageSize) return;
		getRankingResume();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates, page.pageSize, page.pageIndex]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;
		const ORDER_COL_WIDTH = 34;
		const LOGO_COL_WIDTH = 64;
		const CONTROL_COL_WIDTH = 112;

		if (tableWidth > 0) {
			tableWidth = tableWidth - ORDER_COL_WIDTH - LOGO_COL_WIDTH - CONTROL_COL_WIDTH;
		}

		return [
			columnHelper.accessor("rank_number", {
				id: "rank_number",
				header: ({column}) => <DataTableColumnHeader title="Orden" column={column} />,
				cell: info => info.row.original.rank_number,
				size: ORDER_COL_WIDTH + 25,
				enableSorting: false,
			}),
			columnHelper.accessor("photo_url", {
				id: "Logo",
				header: undefined,
				cell: info => (
					<img
						alt="Banner"
						src={info.getValue()}
						className={cn(
							"relative mx-auto aspect-square w-full object-contain text-center",
							"before:absolute before:flex before:h-full before:w-full before:items-center before:justify-center before:border before:border-border before:bg-background before:text-xs before:font-medium before:content-['404']",
						)}
					/>
				),
				size: LOGO_COL_WIDTH,
				enableResizing: false,
				enableColumnFilter: false,
				enableSorting: false,
			}),
			columnHelper.accessor("name", {
				id: "Name",
				header: ({column}) => <DataTableColumnHeader title="Serie" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="overflow-hidden text-ellipsis pl-3 text-left text-neutral-500">{info.row.original.name}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.19),
			}),
			columnHelper.accessor("views", {
				id: "Views",
				header: ({column}) => <DataTableColumnHeader title="Views" column={column} />,
				cell: info => <span className="mr-auto truncate  pl-3 font-medium">{info.row.original.views}</span>,
				size: Math.floor(tableWidth * 0.19),
			}),
			columnHelper.accessor("likes", {
				id: "Likes",
				header: ({column}) => <DataTableColumnHeader title="Likes" column={column} />,
				cell: info => <span className="mr-auto truncate  pl-3 font-medium">{info.row.original.likes}</span>,
				size: Math.floor(tableWidth * 0.19),
			}),
			columnHelper.accessor("follows", {
				id: "Follow",
				header: ({column}) => <DataTableColumnHeader title="Follows" column={column} />,
				cell: info => <span className="mr-auto truncate pl-3 font-medium">{info.row.original.follows}</span>,
				size: Math.floor(tableWidth * 0.19),
			}),
			columnHelper.accessor("memberships", {
				id: "Membresías",
				header: ({column}) => <DataTableColumnHeader title="Membresías" column={column} />,
				cell: info => <span className="mr-auto truncate pl-3 font-medium">{info.row.original.memberships}</span>,
				size: Math.floor(tableWidth * 0.19),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<Button size={"sm"} variant={"outline"} asChild>
						<Link to={"/corporate/series-detail/" + info.row.original.id + "/" + info.row.original.name}>
							<BarsPlus className="h-4 w-4" />
						</Link>
					</Button>
				),
				size: CONTROL_COL_WIDTH,
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	const handleDateRangeChange = (s: number, e: number) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Ranking de Series</h2>
				<Button size={"sm"} variant={"outline"} asChild>
					<Link to={"/corporate/ranked-contents"}>Ranking de Contenidos</Link>
				</Button>
			</div>
			<div className="mt-3 flex w-full flex-row justify-between">
				<DatePickerWithRange
					onDateRangeChange={handleDateRangeChange}
					date={dates}
					className="left-0 w-80"
					disabled={{before: subYears(new Date(), 1), after: new Date()}}
				/>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={data || []}
					pageCount={Math.ceil(totalResults / pageSize)}
					pagination={page}
					loading={isLoading}
					onPaginationChange={setPage}
					withDynamicPageSize
					rowHeight={70}
					showPagination={false}
				/>
			</div>
		</div>
	);
}
