import {endOfToday, format, formatISO9075, roundToNearestMinutes, subYears} from "date-fns";
import {DatePickerWithRange} from "../../../components/blocks/DateRangePicker";
import Select, {SingleValue} from "react-select";
import {selectGlobalStyles} from "../../../../utils/selectGlobalStyles";
import {Button} from "../../../components/primitives/Button";
import {CancelCircleFilled, CheckCircleFilled, ListPlus, Pencil} from "../../../components/primitives/icons";
import {useAppDispatch} from "../../../_store/hooks";
import {useDashboardByNameQuery} from "../../../_store/features/dashboard/hooks";
import {useCallback, useEffect, useMemo, useState} from "react";
import {dashboardActions} from "../../../_store/features/dashboard/dashboard-slice";
import {DateRange} from "react-day-picker";
import {createColumnHelper, PaginationState} from "@tanstack/react-table";
import {Cost, ListCostConceptResponse} from "../../../data-access/dashboard/types";
import {DataTable} from "../../../components/blocks/DataTable";
import Tooltip from "../../../components/primitives/ToolTip-toDeprecate";
import {es} from "date-fns/locale";
import toast from "react-hot-toast";
import EditModal from "./_subcomponents/EditModal";
import InsertModal from "./_subcomponents/InsertModal";

const columnHelper = createColumnHelper<Cost>();

export default function Expenses() {
	const dispatch = useAppDispatch();
	const costsList = useDashboardByNameQuery("listPlatformCosts");

	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});
	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = subYears(to, 1);
		return {
			from,
			to,
		};
	});
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [openInsertDialog, setOpenInsertDialog] = useState(false);

	const [error, setError] = useState("");
	const [data, setData] = useState<Cost | undefined>();

	const [costConceptList, setCostConceptList] = useState<{label: string; value: string}[] | undefined>();
	const [selectedConcept, setSelectedConcept] = useState<string>();

	const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

	useEffect(() => {
		if (!pagination.pageSize) return;
		dispatch(
			dashboardActions.listPlatformCostsAction({
				page: pagination.pageIndex,
				page_size: pagination.pageSize,
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
				searchby: selectedConcept ? "cost_concept" : undefined,
				searchvalue: selectedConcept !== "" ? selectedConcept : undefined,
			}),
		);
		if (!costConceptList?.length) {
			dispatch(dashboardActions.listCostConceptDataAction({page_size: 9999})).then(res => {
				if (res.meta.requestStatus !== "fulfilled") return;
				const conceptListOptions = (res.payload as ListCostConceptResponse).results.map(c => ({label: c.description, value: c.id}));
				conceptListOptions.unshift({label: "Todos los costos", value: ""});
				setCostConceptList(conceptListOptions);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination, dates, selectedConcept]);

	const handleSelectCost = (cost: Cost) => {
		setData({...cost});
		setOpenEditDialog(true);
	};
	const handleDismiss = useCallback(() => {
		setData(undefined);
		setError("");
		setSelectedConcept(undefined);
		setOpenEditDialog(false);
		setOpenInsertDialog(false);
	}, []);

	const handleSelectCostConcept = (newValue: SingleValue<{label: string; value: string}>) => {
		if (!newValue) return;
		setSelectedConcept(newValue.value);
	};

	const onEdit = () => {
		if (!data?.id) return;
		setIsLoadingSubmit(true);
		dispatch(
			dashboardActions.editPlatformCostDataAction({
				id: data.id,
				approved: data.approved,
				description: data.description,
				transaction_date: data.transaction_date,
			}),
		)
			.then(res => {
				if (res.meta.requestStatus === "rejected") {
					setError("Ocurrió un error al intentar guardar los cambios");
					setIsLoadingSubmit(false);
					return;
				}
				toast.success("Cambios guardados correctamente");
				dispatch(
					dashboardActions.listPlatformCostsAction({
						page: pagination.pageIndex,
						page_size: pagination.pageSize,
						initial_date: formatISO9075(dates?.from!),
						final_date: formatISO9075(dates?.to!),
					}),
				);
			})
			.catch(err => {
				setError("Ocurrió un error al intentar guardar los cambios");
				console.log(err);
			})
			.finally(() => {
				handleDismiss();
				setIsLoadingSubmit(false);
			});
	};

	const onAdd = () => {
		if (!data || data.cost <= 0 || !data.cost_concept || !data.transaction_date) return;
		setIsLoadingSubmit(true);
		dispatch(
			dashboardActions.insertPlatformCostDataAction({
				approved: data.approved,
				cost: data.cost,
				cost_concept: data.cost_concept,
				units: data.units,
				description: data.description,
				transaction_date: data.transaction_date,
			}),
		)
			.then(res => {
				if (res.meta.requestStatus === "rejected") {
					setError("Ocurrió un error al intentar guardar los cambios");
					return;
				}
				toast.success("Cambios guardados correctamente");
				dispatch(
					dashboardActions.listPlatformCostsAction({
						page: pagination.pageIndex,
						page_size: pagination.pageSize,
						initial_date: formatISO9075(dates?.from!),
						final_date: formatISO9075(dates?.to!),
					}),
				);
			})
			.catch(err => {
				setError("Ocurrió un error al intentar guardar los cambios");
				console.log(err);
			})
			.finally(() => {
				handleDismiss();
				setIsLoadingSubmit(false);
			});
	};

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;
		const AVATAR_COL_WIDTH = 60;
		if (tableWidth > 0) tableWidth = tableWidth - AVATAR_COL_WIDTH;

		return [
			columnHelper.accessor("cost_concept", {
				id: "Costo",
				header: "Costo",
				cell: info => (
					<div className="flex flex-col">
						<span className="overflow-hidden text-ellipsis text-left">
							{costConceptList?.find(i => i.value === info.getValue())?.label}
						</span>
						<span className="overflow-hidden text-ellipsis text-left text-neutral-500">{info.row.original.cost_concept}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.2),
			}),
			columnHelper.accessor("name", {
				id: "Autor",
				header: "Autor",
				cell: info => (
					<div className="flex flex-col">
						<span className="overflow-hidden text-ellipsis text-left">{info.getValue()}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.16),
			}),
			columnHelper.accessor("cost", {
				id: "Valor",
				header: "Valor",
				cell: info => (
					<div className="flex flex-col">
						<span className="overflow-hidden text-ellipsis text-left">${info.getValue()}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.05),
			}),
			columnHelper.accessor("units", {
				id: "Cantidad",
				header: "Cantidad",
				cell: info => (
					<div className="flex flex-col">
						<span className="overflow-hidden text-ellipsis text-left">{info.getValue()}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.05),
			}),
			columnHelper.accessor("transaction_date", {
				id: "Fecha",
				header: "Fecha",
				cell: info => (
					<div className="flex flex-col">
						<span className="overflow-hidden text-ellipsis text-left">
							{format(new Date(info.getValue()), "d 'de' MMMM 'de' y", {locale: es})}
						</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.16),
			}),
			columnHelper.accessor("audit_updated", {
				id: "Última modificación",
				header: "Última modificación",
				cell: info => (
					<div className="flex flex-col">
						<span className="overflow-hidden text-ellipsis text-left">
							{format(new Date(info.getValue()), "d 'de' MMMM 'de' y", {locale: es})}
						</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.16),
			}),
			columnHelper.accessor("approved", {
				id: "Aprobado",
				header: "Aprobado",
				cell: info => (
					<span
						className={
							"relative inline-block px-3 py-1 font-semibold leading-tight" + (!info.getValue() ? " text-red-700" : " text-green-700")
						}
					>
						<Tooltip text={!info.getValue() ? "Deshabilitado" : "Activo"} placement={info.row.index === 0 ? "bottom" : "top"}>
							{!info.getValue() ? <CancelCircleFilled /> : <CheckCircleFilled />}
						</Tooltip>
					</span>
				),
				size: Math.floor(tableWidth * 0.08),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="ml-2 flex w-full gap-2">
						<Button size={"sm"} variant={"outline"} onClick={() => handleSelectCost({...info.row.original})}>
							<Pencil className="h-4 w-4" />
						</Button>
					</div>
				),
				size: Math.floor(tableWidth * 0.1),
				enableResizing: false,
			}),
		];
	}, [tableRef, costConceptList]);

	const handleDateRangeChange = (s: number, e: number, selectedDate?: DateRange) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	const pageCount = pagination.pageSize ? Math.ceil((costsList.data?.totalResults ?? 0) / pagination.pageSize) : 1;

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Listado de Costos</h2>
				<Button className="gap-2" onClick={() => setOpenInsertDialog(true)}>
					<ListPlus />
					Nuevo costo
				</Button>
			</div>
			<div className="flex w-full items-center justify-between p-6">
				<DatePickerWithRange
					onDateRangeChange={handleDateRangeChange}
					disabled={{before: subYears(new Date(), 1), after: new Date()}}
					date={dates}
				/>
				<Select
					className="basic-multi-select w-48 rounded"
					defaultValue={costConceptList?.[0]}
					options={costConceptList}
					styles={selectGlobalStyles}
					placeholder="Todos los costos"
					onChange={handleSelectCostConcept}
					isLoading={costsList.isLoading}
				/>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={costsList.data?.results || []}
					pageCount={pageCount}
					pagination={pagination}
					loading={costsList.isLoading}
					onPaginationChange={setPagination}
					withDynamicPageSize
					rowHeight={70}
					showPagination={false}
				/>
			</div>
			<EditModal
				isLoading={isLoadingSubmit}
				openDialog={openEditDialog}
				setOpenDialog={setOpenEditDialog}
				error={error}
				setError={setError}
				data={data}
				onDismiss={handleDismiss}
				onSubmit={onEdit}
				setData={setData}
			/>
			<InsertModal
				costConcepts={costConceptList}
				isLoading={isLoadingSubmit}
				openDialog={openInsertDialog}
				error={error}
				setError={setError}
				data={data}
				onDismiss={handleDismiss}
				onSubmit={onAdd}
				setData={setData}
				setCostConceptsList={setCostConceptList}
			/>
		</div>
	);
}
