import {PropsWithChildren, useEffect, useMemo, useState} from "react";
import {Line} from "react-chartjs-2";
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartOptions} from "chart.js";
import {auth} from "../../../../firebase";
import {addDays, endOfToday, roundToNearestMinutes, startOfDay, subDays, formatISO9075, subYears} from "date-fns";
import {DateRange} from "react-day-picker";
import {DatePickerWithRange} from "../../../../components/blocks/DateRangePicker";
import {Eye, Spinner} from "../../../../components/primitives/icons";
import {getContentViews} from "../../../../data-access/series/content";
import {Card} from "../../../../components/blocks/Stats/Card";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface ViewsTimeSeriesProps {
	contentid?: string;
	codeReferral?: string;
	title?: string;
	loading?: boolean;
}

function formatDate(dateStr: string) {
	const date = new Date(dateStr);
	return date.toLocaleDateString("es-US", {month: "short", day: "numeric"});
}

const options: ChartOptions<"line"> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: "top" as const,
		},
	},
};

function ViewsChart(props: PropsWithChildren<ViewsTimeSeriesProps>) {
	const {contentid, title = "Visualizaciones diarias", children} = props;
	const [error, setError] = useState("");
	const [dates, setSelectedDate] = useState<DateRange>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = startOfDay(subDays(Date.now(), 30));
		return {
			from,
			to,
		};
	});
	const [timeSerie, setTimesSerie] = useState<{views: number; date: string}[]>();
	const [innerLoading, setInnerLoading] = useState(false);

	const data = useMemo(
		() => ({
			labels: timeSerie?.map(item => {
				const date = new Date(item.date);
				const format = addDays(date, 1);
				return formatDate(format.toISOString());
			}),
			datasets: [
				{
					label: "Visualizaciones totales",
					data: timeSerie?.map(item => Number(item.views)),
					borderColor: "rgba(242, 116, 116, 1)",
					backgroundColor: "rgba(242, 116, 116, 0.5)",
				},
			],
		}),
		[timeSerie],
	);

	const handleDateRangeChange = (s: number, e: number, selectedDate?: DateRange) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	useEffect(() => {
		async function getAsyncViews() {
			try {
				setError("");
				setInnerLoading(true);
				const token = await auth.currentUser?.getIdToken();

				if (!token) {
					return setError("Invalid token");
				}

				let filters: string[] = [];

				if (contentid) filters.push(`video_id:${contentid}`);

				const {data} = await getContentViews(token, {
					content_id: contentid,
					initial_date: formatISO9075(dates.from!),
					final_date: formatISO9075(dates.to!),
				});

				setTimesSerie(data.views);
				setInnerLoading(false);
			} catch (err) {
				setError("Error cargando las estadísticas " + err);
				setInnerLoading(false);
			}
		}

		getAsyncViews();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentid, dates]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 py-6 pt-0 md:grid-cols-2 lg:grid-cols-7">
			<div className="col-span-6 rounded-lg border bg-card text-card-foreground shadow-sm">
				{!!error && <div className="rounded-sm bg-red-500 p-2 text-red-700">{error}</div>}
				<div className="mb-4 flex items-center justify-between pl-6 pt-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">{title}</h3>
					<div className="w-80 flex-row justify-end">
						<DatePickerWithRange
							onDateRangeChange={handleDateRangeChange}
							disabled={{before: subYears(new Date(), 1), after: new Date()}}
							date={dates}
						/>
					</div>
					{!!children && children}
				</div>
				{props.loading || innerLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<div className="p-6 pt-0">
						<Line height={350} options={options} data={data} />
					</div>
				)}
			</div>
			<div className="grid-cols-1">
				<Card
					beforeIcon={<Eye className="mr-10 opacity-50" width={28} height={28} />}
					title="Total de vistas"
					titleSeparator
					amount={timeSerie?.reduce((acc, curr) => acc + curr.views, 0) || 0}
					isMoney={false}
					loading={props.loading || innerLoading}
				/>
			</div>
		</div>
	);
}

export default ViewsChart;
