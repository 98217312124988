import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {useNavigate, useParams} from "react-router-dom";
import {DateRange} from "react-day-picker";
import {endOfToday, formatISO9075, roundToNearestMinutes, subYears} from "date-fns";
import {dashboardActions} from "../../../_store/features/dashboard/dashboard-slice";
import {Spinner} from "../../../components/primitives/icons";
import {DatePickerWithRange} from "../../../components/blocks/DateRangePicker";
import {Button} from "../../../components/primitives/Button";
import {GeneralIncome} from "../../../components/blocks/Stats/GeneralIncome";
import {PeriodIncome} from "../../../components/blocks/Stats/PeriodIncome";
import PartnerSeriesViews from "../../../components/blocks/Stats/PartnerSeriesViews";
import {LikesAndFavorites} from "../../../components/blocks/Stats";
import CoinEarningsTable from "../../dashboard/role-based/marketing/_subcomponents/CoinEarningsTable";

function SeriesDetail() {
	const {id, name} = useParams<{id: string; name: string}>();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const {loading} = useAppSelector(state => state.series);
	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = subYears(to, 1);
		return {
			from,
			to,
		};
	});

	useEffect(() => {
		dispatch(
			dashboardActions.getGeneralSeriesIncomeAndProfit({
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
				seriesid: id,
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates, id]);

	const handleDateRangeChange = (s: number, e: number, selectedDate?: DateRange) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	return (
		<div className="flex h-screen flex-col">
			{loading ? (
				<div className="flex h-screen items-center justify-center">
					<Spinner />
				</div>
			) : (
				<>
					<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
						<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">{`Información - ${name}`}</h2>
					</div>

					<div className="flex flex-col gap-3 py-6">
						<div className="flex w-full flex-row justify-between">
							<DatePickerWithRange
								onDateRangeChange={handleDateRangeChange}
								date={dates}
								className="left-0 w-80"
								disabled={{before: subYears(new Date(), 1), after: new Date()}}
							/>
							<Button
								type="button"
								variant="blueBtn"
								size="lg"
								className="mr-8 w-60 py-1.5"
								onClick={() => {
									navigate("/corporate/purchases");
								}}
							>
								Ver todas las compras
							</Button>
						</div>
						<div className="flex flex-col gap-3 px-8 py-6">
							<GeneralIncome serieId={id} dates={dates} />
							<PeriodIncome serieId={id} dates={dates} />
							<PartnerSeriesViews serieid={id} />
							<LikesAndFavorites seriesIds={id!} isCorporate dates={dates} />
							<CoinEarningsTable serieid={id} dates={dates} />
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default SeriesDetail;
